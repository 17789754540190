import {
  common,
} from '@mui/material/colors';

export default () => {
  const colors = {
    white: common.white,
    background: '#fafafa',
    primary: '#007A89',
    secondary: '#4A4A4A',
    positive: '#4ade80',
    medium: '#facc15',
    negative: '#b91c1c',
    neutral: '#71717a',
    geometry: '#3bb2d0',
  };

  return {
    background: {
      default: colors.background,
    },
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.white,
    },
    colors,
  };
};
